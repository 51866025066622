import { css } from '@emotion/react'
import { spacing } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'
import { useLargeScreen } from '../../lib/use-large-screen'

type CardBodyProps = {
  children: ReactNode
  className?: string
}

export function CardBody(props: CardBodyProps): JSX.Element {
  const { children, className } = props
  const isLargeScreen = useLargeScreen()
  return (
    <div
      className={className}
      css={css`
        padding: ${isLargeScreen ? spacing[70] : spacing[60]};
      `}
    >
      {children}
    </div>
  )
}
